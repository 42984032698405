export function currencyNumber(number: number) {
  if (number) {
    return Math.round(number);
  } else {
    return 0;
  }
}

export function convertPenniesToDollars(amount: number) {
  return amount / 100;
}
